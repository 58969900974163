<template>
  <div class="text-box">
    <div class="label" v-for="(item, index) in list" :key="index">
      <i>{{ item.id }} /</i>
      {{ item.label }}
      <img v-if="item.image" src="@/assets/images/statement/cur.png" alt />
      <span v-if="item.image">{{ item.label2 }}</span>
      <ul v-if="item.children">
        <li v-for="(o, oIndex) in item.children" :key="oIndex">
          <span></span>
          {{ o }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.list = [
        {
          id: "01",
          label:
            "We offer excellent services, as we offer our customers the best from the best. Our team is always at your disposal and all your requests will get an answer as soon as you ask. ",
        },
        {
          id: "02",
          label: "We do not offer refunds or returns for the following issues:",
          children: [
            "Open two tabs to download two or more identical files at the same time.",
            "Uncommon operation, double click or multiple click on the 'Confirm Download' button.",
            "Your browser showing crashes/errors, program errors, etc.",
            "File upload error by the author or third-party server crashes.",
            "WInternet connection problems.",
          ],
        },
        {
          id: "03",
          label:
            "If the third-party website is down for maintenance/ cannot be accessed normally/ closed, etc., we will issue an announcement immediately.",
        },
        {
          id: "04",
          label:
            "When downloading images, users must comply with the \"Standard License\" of each stock photo website. If the user requires an image with an Enhance or Extended license please contact the customer support team for more information.",
        },
        {
          id: "05",
          label:
            "You can redownload every documents in 'Download History' for free within three days!",
        },
        {
          id: "06",
          label:
            "We guarantee that the default file downloaded is EPS, if there is no EPS, it is the highest size JPG. File format: EPS/JPG/ZIP",
        },
        {
          id: "07",
          label: "If the third party website is showing",
          image: true,
          label2:
            "【updating status】, it usually takes 15 minutes to 8 hours (non-working days may be takes longer). At this time, you can choose other websites to download.",
        },
        {
          id: "08",
          label:
            "If you want to purchase other videos, music, 3D files or other website materials, please contact our customer service team to get a quote!",
        },
        {
          id: "09",
          label:
            "If the file download on slow or unstable connection, please check your network connection. It is recommended to restart the router and try again!",
        },
        {
          id: "10",
          label:
            "When you [register]/[login] and start using this site, your use of behavior will be regarded as an endorsement and acknowledgement of the entire statement in this website.",
        },
        {
          id: "11",
          label:
            "If you do not understand these terms and conditions, please contact our customer service team for further details.",
        },
        {
          id: "12",
          label: "Violations of any  policy can lead to an account suspension.",
        },
        {
          id: "13",
          label:
            "We reserve the right to change or modify any of the terms and conditions contained in the Terms or any policy or guideline of the Sites, at any time and in our sole discretion",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.text-box {
  i {
    position: absolute;
    left: 0;
    top: 0;
  }
  .label {
    line-height: 40px;
    color: #1f1f1f;
    font-size: 14px;
    padding-left: 34px;
    position: relative;
  }
  ul {
    padding: 10px 0 20px 0;
    li {
      line-height: 30px;
      color: #545454;
      position: relative;
      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 1px;
        background: #000;
      }
    }
  }
}
</style>
